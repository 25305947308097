.error-page {
    margin: 10rem auto;
    max-width: 70rem;
    position: relative;
    padding: 0 1rem;
    color: #000000;
    h1 {
        font-size: 6rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: inherit;
    }

    h2 {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: inherit;
    }

    img {
        max-width: 100%;
        height: auto;
        width: 20rem;
    }

    @media (max-width: 991px) {
        margin: 4rem auto;
        img {
            width: 15rem;
        }
    }

    a {
        display: inline-block;
        padding: 0.7rem 1.5rem;
        font-size: 1rem;
        background: #ffffff;
        color: #000000;
        border: 1px solid #000000;
        margin-top: 1rem;
        transition: all 0.5s;

        &:hover {
            background: #000000;
            color: #ffffff;
        }
    }
}
